import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import App from './App';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import common_ar from './translations/ar/common.json';
import common_en from './translations/en/common.json';
import common_de from './translations/de/common.json';
import common_tr from './translations/tr/common.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'ar',
  resources: {
    ar: {
      common: common_ar,
    },
    en: {
      common: common_en,
    },
    de: {
      common: common_de,
    },
    tr: {
      common: common_tr,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
