import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const About: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
  const isRtl = i18n.dir() === 'rtl';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  return (
    <Container
      fluid
      style={{ marginBottom: '20px' }}
      className={isRtl ? 'rtl' : ''}
    >
      <div>
        <Link to="/">
          <Button className="nav-button button-color">
            <i className="fa-solid fa-arrow-left"></i>
          </Button>
        </Link>
      </div>
      <Row>
        <Col>
          <h1>{t('About.header')}</h1>
          <br />
          {t('About.paragraph')}
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          {t('About.explain')}
          <br />
          {t('About.projects.title')}
          <ul style={{ marginTop: '10px' }}>
            <li>{t('About.projects.project1')}</li>
            <li>{t('About.projects.project2')}</li>
            <li>{t('About.projects.project3')}</li>
            <li>{t('About.projects.project4')}</li>
            <li>{t('About.projects.project5')}</li>
            <li>{t('About.projects.project6')}</li>
            <li>{t('About.projects.project7')}</li>
            <li>{t('About.projects.project8')}</li>
            <li>{t('About.projects.project9')}</li>
            <li>{t('About.projects.project10')}</li>
            <li>{t('About.projects.project11')}</li>
            <li>{t('About.projects.project12')}</li>
            <li>{t('About.projects.project13')}</li>
            <li>{t('About.projects.project14')}</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
