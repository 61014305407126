import React, { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 1000);
  });

  return (
    <div className="container-ramadan-page">
      <Row className="d-flex justify-content-center row-ramadan">
        <Col>
          <p>Page not found!</p>
          <Link to="/">
            <Button>
              <i className="fa fa-home" aria-hidden="true"></i>
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default NotFound;
