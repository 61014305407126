import React, { useState } from 'react';
import { Image, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/LandingPage.css';
import '../css/carousel.css';

const LandingPage = () => {
  const { t } = useTranslation('common');

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: React.SetStateAction<number>) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <section>
        <header>
          <div className='landing-page-header'>
            <div className='overlay'>
              <h1 className='overlay-header urgent'>Takaful Austria</h1>

              <Row>
                <Col>
                  <Button className='landing-button' variant='primary'>
                    <Link
                      to='https://www.paypal.com/donate?hosted_button_id=5TE27MD4C6WA2'
                      target='_blank'
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      {t('LandingPage.header.button2')}
                    </Link>
                  </Button>
                </Col>
                <Col>
                  <Button className='landing-button' variant='primary'>
                    <Link
                      to='/about'
                      style={{ textDecoration: 'none', color: 'white' }}
                    >
                      {t('LandingPage.header.button1')}
                    </Link>
                  </Button>
                </Col>
              </Row>
            </div>
            <img
              className='urgent-img'
              src='/assets/images/takaful-2.jpeg'
              alt='takaful projects in norhtern syria'
            />
          </div>
        </header>
      </section>

      <div className='mb-5'>
        <article>
          <div className='d-flex flex-column justify-content-center align-items-center gap-3'>
            <Image className='logo' src='/assets/images/logo-en-max.png' />
            <p className='text-black-50 w-75 text-center'>
              {t('paragraph.body')}
            </p>
          </div>
        </article>

        <section>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            draggable={false}
            slide={true}
          >
            <Carousel.Item>
              <Card style={{ width: '100%', height: '25rem' }}>
                <Link
                  to='/eid'
                  style={{ textDecoration: 'none', marginTop: '3%' }}
                >
                  <Card.Img
                    draggable={false}
                    className='border-0'
                    src='/assets/images/takaful-10.jpeg'
                  ></Card.Img>
                  <Card.Body className='d-flex align-items-center'>
                    <Card.Title className='text-dark'>
                      <h3>
                        {t('LandingPage.carousel.project_eid_al_adha_title')}
                      </h3>
                    </Card.Title>
                    <Card.Text className='text-black-50 card-text'>
                      {t(
                        'LandingPage.carousel.project_eid_al_adha_description'
                      )}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Carousel.Item>

            <Carousel.Item>
              <Card style={{ width: '100%', height: '25rem' }}>
                <Link
                  to='/zelzal'
                  style={{ textDecoration: 'none', marginTop: '3%' }}
                >
                  <Card.Img
                    draggable={false}
                    className='border-0'
                    src='/assets/images/zelzal-syria.jpg'
                  ></Card.Img>
                  <Card.Body className='d-flex align-items-center'>
                    <Card.Title className='text-dark'>
                      <h3>{t('LandingPage.carousel.project1_title')}</h3>
                    </Card.Title>
                    <Card.Text className='text-black-50 card-text'>
                      {t('LandingPage.carousel.project1_description')}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Carousel.Item>

            <Carousel.Item>
              <Card style={{ width: '100%', height: '25rem' }}>
                <Link
                  to='/pump'
                  style={{ textDecoration: 'none', marginTop: '3%' }}
                >
                  <Card.Img
                    draggable={false}
                    className='border-0'
                    src='/assets/images/well.jpeg'
                  ></Card.Img>
                  <Card.Body className='d-flex align-items-center'>
                    <Card.Title className='text-dark'>
                      <h3>
                        {t('LandingPage.carousel.project_water_pump_title')}
                      </h3>
                    </Card.Title>
                    <Card.Text className='text-black-50 card-text'>
                      {t('LandingPage.carousel.project_water_pump_description')}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Carousel.Item>

            <Carousel.Item>
              <Card style={{ width: '100%', height: '25rem' }}>
                <Link
                  to='/residential-6'
                  style={{ textDecoration: 'none', marginTop: '3%' }}
                >
                  <Card.Img
                    draggable={false}
                    className='border-0'
                    src='/assets/images/takaful-8.jpeg'
                  ></Card.Img>
                  <Card.Body className='d-flex align-items-center'>
                    <Card.Title className='text-dark'>
                      <h3>
                        {t(
                          'LandingPage.carousel.project_residential_takaful_6_title'
                        )}
                      </h3>
                    </Card.Title>
                    <Card.Text className='text-black-50 card-text'>
                      {t(
                        'LandingPage.carousel.project_residential_takaful_6_description'
                      )}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Carousel.Item>
          </Carousel>
        </section>

        <div className='title'></div>

        {/* <Carousel.Item>
              <Card style={{ width: '100%', height: '25rem' }}>
                <Card.Img
                  draggable={false}
                  className='border-0'
                  src='/assets/images/takaful-9.jpeg'
                ></Card.Img>
                <Link to='/ramadan' style={{ textDecoration: 'none' }}>
                  <Card.Body className='d-flex align-items-center'>
                    <Card.Title className='text-dark'>
                      <h3>{t('LandingPage.carousel.project2_title')}</h3>
                    </Card.Title>
                    <Card.Text className='text-black-50'>
                      {t('LandingPage.carousel.project2_description')}
                    </Card.Text>
                  </Card.Body>
                </Link>
              </Card>
            </Carousel.Item> */}

        {/* <section>
          <h2 className='title mb-5 carousel-header-h2'>
            {t('LandingPage.cards.header-title')}
          </h2>

          <Row>
            <Col md='4' className='text-center mb-4'>
              <Card>
                <Link to='/zelzal'>
                  <Card.Img
                  draggable={false}
                    variant='top'
                    src='/assets/images/earthquake-syria.jpeg'
                    width='250px'
                    height='250px'
                  />
                </Link>
                <Card.Body>
                  <Card.Title>
                    {t('LandingPage.cards.earthquake-card.title')}
                  </Card.Title>
                  <Card.Text>
                    {t('LandingPage.cards.earthquake-card.card')}
                  </Card.Text>
                  <Link to='/zelzal'>
                    {t('LandingPage.cards.earthquake-card.link')}
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md='4' className='text-center mb-4'>
              <Card>
                <Link to='/pump'>
                  <Card.Img
                  draggable={false}
                    variant='top'
                    src='/assets/images/takaful-7.jpeg'
                    width='250px'
                    height='250px'
                  />
                </Link>
                <Card.Body>
                  <Card.Title>
                    {t('LandingPage.cards.water-pump-card.title')}
                  </Card.Title>
                  <Card.Text>
                    {t('LandingPage.cards.water-pump-card.card')}
                  </Card.Text>
                  <Link to='/pump'>
                    {t('LandingPage.cards.water-pump-card.link')}
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md='4' className='text-center mb-4'>
              <Card>
                <Link to='/residential-6'>
                  <Card.Img
                  draggable={false}
                    variant='top'
                    src='/assets/images/takaful-2.jpeg'
                    width='250px'
                    height='250px'
                  />
                </Link>
                <Card.Body>
                  <Card.Title>
                    {t('LandingPage.cards.residential-6.title')}
                  </Card.Title>
                  <Card.Text>
                    {t('LandingPage.cards.residential-6.card')}
                  </Card.Text>
                  <Link to='/residential-6'>
                    {t('LandingPage.cards.residential-6.link')}
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md='4' className='text-center mb-4'>
              <Card>
                <Link to='/cancer'>
                  <Card.Img
                  draggable={false}
                    variant='top'
                    src='/assets/images/takaful-cancer-3.jpeg'
                    width='250px'
                    height='250px'
                  />
                </Link>
                <Card.Body>
                  <Card.Title>{t('LandingPage.cards.cancer.title')}</Card.Title>
                  <Card.Text>{t('LandingPage.cards.cancer.card')}</Card.Text>
                  <Link to='/cancer'>{t('LandingPage.cards.cancer.link')}</Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md='4' className='text-center mb-4'>
              <Card>
                <Link to='/eid'>
                  <Card.Img
                  draggable={false}
                    variant='top'
                    src='/assets/images/eid-adha-card.jpeg'
                    width='250px'
                    height='250px'
                  />
                </Link>
                <Card.Body>
                  <Card.Title>
                    {t('LandingPage.cards.eid-al-adha-card.title')}
                  </Card.Title>
                  <Card.Text>
                    {t('LandingPage.cards.eid-al-adha-card.card')}
                  </Card.Text>
                  <Link to='/eid'>
                    {t('LandingPage.cards.eid-al-adha-card.link')}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section> */}
      </div>
    </div>
  );
};

export default LandingPage;
