import React, { useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Contact: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation('common');
  const isRtl = i18n.dir() === 'rtl';

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  return (
    <div className={isRtl ? 'rtl' : ''}>
      <Container className="text-left text-sm-left text-md-left text-lg-left text-xl-left contact">
        <div>
          <Link to="/">
            <Button className="nav-button button-color">
              <i className="fa-solid fa-arrow-left"></i>
            </Button>
          </Link>
        </div>
        <Row className="justify-content-md-center mb-5">
          <Col xs={12} md={8}>
            <h1>{t('Contact.title')}</h1>
            <br />
            <p>
              {t('Contact.text1')}
              <br />
              +43 688 60488188
            </p>
            <p>
              {t('Contact.text2')}
              <br />
              <a href="mailto:office@takaful-austria.org">
                office@takaful-austria.org
              </a>
            </p>
            <p>
              {t('Contact.text3')}
              <br />
              <a href="mailto:feedback@takaful-austria.org">
                feedback@takaful-austria.org
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
