import React, { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const Impressum = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  return (
    <Container className="text-left text-sm-left text-md-left text-lg-left text-xl-left">
      <section>
        <div>
          <Link to="/">
            <Button className="nav-button button-color">
              <i className="fa-solid fa-arrow-left"></i>
            </Button>
          </Link>
        </div>
      </section>
      <section>
        <article>
          <div className="impressum-text">
            <h1>Impressum</h1>
            <p>
              Informationspflicht laut §5 E-Commerce Gesetz, §14
              Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht
              laut §25 Mediengesetz.
            </p>
            <p>
              <strong>Verein für soziale Solidarität, Takaful</strong>
              <br />
              <strong>ZVR:</strong> 1830489208
            </p>
            <p>
              Hernalser Gürtel 31/18-19,
              <br />
              1170 Wien,
              <br />
              Österreich
            </p>
            <p>
              <strong>Organschaftliche Vertreter</strong>
              <br />
              Obmann: Ziad Kharfan
              <br />
              Schriftführer: Hasan Ali
              <br />
              Kassier: Jamal Eddin Alsabra
            </p>
            <p>
              <strong>Organisationsform:</strong> Verein
              <br />
              <strong>Vereinssitz:</strong> 1170 Wien
              <br />
              <strong>Tel.:</strong> +43 688 60488188
              <br />
              <strong>Webseite: </strong>
              <a href="https://takaful-austria.org">takaful-austria.org</a>
              <br />
              <strong>E-Mail: </strong>
              <a href="mailto:office@takaful-austria.org">
                office@takaful-austria.org
              </a>
            </p>
            <p>
              <strong>Bankkonto:</strong>
              <br />
              Bank: BAWAG P.S.K.
              <br />
              IBAN: AT83 1400 0051 1088 3759
              <br />
              BIC: BAWAATWW
            </p>
            <p>
              <strong>Vereinszweck:</strong>
              <br />
              Takaful ist ein Wohltätigkeitsverein, deren Ziel ist, Flüchtlingen
              und Obdachlosen im Norden Syrien zu helfen.
            </p>
            <strong>Haftung für Inhalte dieser Website</strong>
            <br />
            <p>
              Wir entwickeln die Inhalte dieser Website ständig weiter und
              bemühen uns korrekte und aktuelle Informationen bereitzustellen.
              Leider können wir keine Haftung für die Korrektheit aller Inhalte
              auf dieser Website übernehmen, speziell für jene, die seitens
              Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht
              verpflichtet, die von ihnen übermittelten oder gespeicherten
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen.
            </p>
            <p>
              Wir haben keinen Einfluss auf die Gestaltung und den Inhalt
              externer Websites. Wir übernehmen daher keine Verantwortung für
              alle externen Inhalte, auch wenn Seiten unserer eigenen Website
              auf diese externen Seiten verlinken. Wir bemühen uns nach Kräften,
              dass die Informationen auf dieser Website aktuell und korrekt
              sind. Das Auftreten von Fehlern kann jedoch nicht vollständig
              ausgeschlossen werden. Es besteht kein Anspruch auf
              Vollständigkeit. Daher nehmen wir keine Haftung für die
              Aktualität, Richtigkeit oder Vollständigkeit der auf der Website
              präsentierten Informationen.
            </p>
            <p>
              Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen,
              bitte wir Sie uns umgehend zu kontaktieren, damit wir die
              rechtswidrigen Inhalte entfernen können. Sie finden die
              Kontaktdaten im Impressum.
            </p>
            <strong>Haftung für Links auf dieser Website</strong>
            <br />
            <p>
              Unsere Website enthält Links zu anderen Websites, für deren Inhalt
              wir nicht verantwortlich sind. Wir haften daher nicht für
              verlinkte Websites und haben keine Kenntnis von irgendwelchen
              rechtswidrigen Aktivitäten auf verlinkten Websites und haben
              bisher auch keine derartigen Rechtsverstöße festgestellt. Bei
              Bekannt werden von Rechtsverstößen werden wir derartige Links
              umgehend entfernen.
            </p>
            <p>
              Wenn Ihnen rechtswidrige Links auf unserer Website auffallen,
              bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im
              Impressum.
            </p>
            <strong>Urheberrechtshinweis</strong>
            <br />
            <p>
              Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos)
              unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die
              Inhalte dieser Website verbreiten, vervielfältigen oder verwerten
              wie zum Beispiel auf anderen Websites erneut veröffentlichen.
              Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der
              Inhalte unserer Seite rechtlich verfolgen.
            </p>
            <p>
              Die Verwendung von Daten, einschließlich der Einspeisung in
              Online-Dienste, Datenbanken oder Websites durch unbefugte Dritte,
              ist untersagt.
            </p>
            <p>
              Sollten Sie auf dieser Webseite Inhalte finden, die das
              Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
            </p>
            <strong>Datenschutz</strong>
            <br />
            <p>
              Sollten Sie Fragen zum Datenschutz haben, wenden Sie sich bitte an
              die Kontaktdaten.
            </p>
            <p>
              Die Verwendung der im Rahmen dieses Impressums veröffentlichten
              Kontaktdaten oder vergleichbarer Informationen wie
              Postanschriften, Telefonummer sowie E-Mail-Adresse durch Dritte
              zum Zwecke der Zusendung nicht ausdrücklich angeforderter
              Informationen ist nicht gestattet. Rechtliche Schritte gegen die
              Absender von unerwünschten Werbebriefen und Telefonanrufen,
              Spam-E-Mails und weiteren Verstößen gegen dieses Verbot sind
              ausdrücklich vorbehalten.
            </p>
            <br />
          </div>
        </article>
      </section>
    </Container>
  );
};

export default Impressum;
