import React, { useState } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../css/Footer.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation('common');

  const emailSubscribtionHandler = async (e: any) => {
    e.preventDefault();

    const emailValidationRegex =
      /^(?=.{1,64}@.{1,130}$)[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Validate email
    if (!emailValidationRegex.test(email)) {
      setError('Ungültige E-Mail Adresse');
      setTimeout(() => {
        setError('');
      }, 2000);
      return;
    } else if (email.length >= 66) {
      setError('E-Mail Adresse ist zu lang');
      setTimeout(() => {
        setError('');
      }, 2000);
      return;
    }
    // Submit the email address to a back-end server or email marketing service
    setSubscribed(true);

    // Make a server request to store the email data
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data, status } = await axios.post(
        '/api/subscribe',
        { email },
        config
      );

      if (status > 204) {
        setError(data.message);

        throw new Error(data.message);
      }

      alert('Danke!');
    } catch (error: any) {
      console.error('error', error);
      setError(error.response.data.message);
    }
  };
  return (
    <>
      <footer>
        <hr />
        <Row
          style={{ alignItems: 'center', textAlign: 'center' }}
          className="footer-flex"
        >
          <Col>
            <div className="footer-buttons social-icons">
              <Link to="/contact-us">
                <Button className="footer-button-elements">
                  {t('Footer.buttons.contact')}
                </Button>
              </Link>
              <Link to="/impressum">
                <Button className="footer-button-elements">
                  {t('Footer.buttons.imprint')}
                </Button>
              </Link>
              <Button
                className="footer-button-elements"
                style={{ backgroundColor: '#3b5998' }}
                href="https://www.facebook.com/Takaful.Austria/"
              >
                <MDBIcon fab icon="facebook-f" />
              </Button>
              <Button
                className="footer-button-elements"
                style={{ backgroundColor: '#ed302f' }}
                href="https://www.youtube.com/@takaful-austria"
              >
                <MDBIcon fab icon="youtube" />
              </Button>
            </div>
          </Col>
          <Col
            className="mb-5 mt-5 input-wrapper"
            style={{ textAlign: 'start' }}
          >
            <Form className="" onSubmit={emailSubscribtionHandler}>
              {error ? <span style={{ color: 'red' }}>{error}</span> : ''}
              {subscribed ? (
                <p>{t('subscribebutton.success')}</p>
              ) : (
                <>
                  <Form.Group className="mb-3 text w-75" controlId="email">
                    <Form.Label>{t('email.label')}</Form.Label>
                    <Form.Control
                      className="email-input"
                      type="email"
                      //@ts-ignore
                      placeholder={t('email.placeholder')}
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text className="text-muted hint-text">
                      {t('email.hinttext')}
                    </Form.Text>
                  </Form.Group>
                </>
              )}

              <Button
                variant="primary button-color"
                type="submit"
                disabled={!!subscribed}
              >
                {t('subscribebutton.label')}
              </Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <div className="text-footer input-wrapper">
            {t('Footer.copyright')}&nbsp;
            <a href="https://www.takaful-austria.org/"> Takaful</a>
          </div>
        </Row>
      </footer>
    </>
  );
};

export default Footer;
