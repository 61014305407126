import React from 'react';
import { NavbarBrand, NavItem } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';

function NavBar() {
  const { t, i18n } = useTranslation('common');

  return (
    <Navbar bg='light' expand='lg'>
      <Container>
        <NavbarBrand>
          <Link to='/' style={{ textDecoration: 'none', color: '#000' }}>
            {t('NewNavBar.NavbarBrand.title')}
          </Link>
        </NavbarBrand>

        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            {/* <NavDropdown
              title={t('NewNavBar.NavDropdown.ramadannavbar_title')}
              id="ramadannavbar"
            >
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  to="/ramadan"
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  {t('NewNavBar.NavDropdown.ramadan')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  to="/ramadan/zakat"
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  {t('NewNavBar.NavDropdown.zakat')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
       
                <Nav.Link
                  as={Link}
                  to="/ramadan/fitra"
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  {t('NewNavBar.NavDropdown.fitra')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
            
                <Nav.Link
                  as={Link}
                  to="/ramadan/iftar"
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  {t('NewNavBar.NavDropdown.iftar')}
                </Nav.Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Nav.Link
                  as={Link}
                  to="/ramadan/basket"
                  style={{ textDecoration: 'none', color: '#000' }}
                >
                  {t('NewNavBar.NavDropdown.basket')}
                </Nav.Link>
              </NavDropdown.Item>
            </NavDropdown> */}

            <NavDropdown
              title={t('NewNavBar.NavDropdown.linksnavbar_title')}
              id='linksnavbar'
            >
              <NavDropdown.Item>
                <Link
                  style={{ textDecoration: 'none', color: '#000' }}
                  to='/impressum'
                >
                  {t('NewNavBar.NavDropdown.imprint')}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  style={{ textDecoration: 'none', color: '#000' }}
                  to='/contact-us'
                >
                  {t('NewNavBar.NavDropdown.contact_us')}
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link
                  style={{ textDecoration: 'none', color: '#000' }}
                  to='/about'
                >
                  {t('NewNavBar.NavDropdown.about_us')}
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <NavItem>
          <p style={{ marginTop: '18px', marginRight: '20px' }}>
            {t('NewNavBar.thanks')}
          </p>
        </NavItem>
        <NavItem>
          <NavDropdown
            title={<i className='fa-sharp fa-solid fa-globe'></i>}
            id='languageDropdown'
          >
            <NavDropdown.Item onClick={() => i18n.changeLanguage('ar')}>
              🇸🇦
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('en')}>
              🇬🇧
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('de')}>
              🇩🇪
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => i18n.changeLanguage('tr')}>
              🇹🇷
            </NavDropdown.Item>
          </NavDropdown>
        </NavItem>
      </Container>
    </Navbar>
  );
}

export default NavBar;
