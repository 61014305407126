import { OrderResponseBody } from '@paypal/paypal-js';
import axios from 'axios';
import { IPaypalPayload } from './interfaces';

export const addDecimals = (num: number): string => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const saveTransactionToDB = async (
  path: string,
  paypalPayload: OrderResponseBody,
  description: string
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const payload = {
    orderId: paypalPayload.id,
    create_time: paypalPayload.create_time,
    amount: {
      value: paypalPayload.purchase_units[0].amount.value,
      currency_code: paypalPayload.purchase_units[0].amount.currency_code
        ? paypalPayload.purchase_units[0].amount.currency_code
        : '',
    },
    email: paypalPayload.payer.email_address
      ? paypalPayload.payer.email_address
      : '',
    status: paypalPayload.status,
    description,
  } as IPaypalPayload;
  const { data } = await axios.post(`/api/${path}`, payload, config);

  console.log('post req to be to store id', data);
};
