import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js';
import { usePayPalScriptReducer, PayPalButtons } from '@paypal/react-paypal-js';
// import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { addDecimals, saveTransactionToDB } from '../lib/helper';
import { Link, useLocation } from 'react-router-dom';
import '../css/ZelzalScreen.css';

const ZelzalScreen: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  const [amount, setAmount] = useState('');

  const [isPayed, setIsPayed] = useState(false);
  const { t } = useTranslation('common');
  const [toggleAmountField, setToggleAmountField] = useState(false);

  const [errorAmount, setErrorAmount] = useState('');
  // const [, setTransactionsAmount] = useState(0);

  // const [{ options }, dispatch] = usePayPalScriptReducer();
  // const [currency, setCurrency] = useState(options.currency);

  // const onCurrencyChange = ({ target: { value } }: any) => {
  //   setCurrency(value);
  //   dispatch({
  //     type: 'resetOptions',
  //     value: {
  //       ...options,
  //       currency: value,
  //     },
  //   });
  // };

  // const currencies = [
  //   {
  //     value: 'EUR',
  //     label: '€',
  //   },
  //   {
  //     value: 'USD',
  //     label: '$',
  //   },
  // ];

  // const getTotalTransactionsAmount = async () => {
  //   const { data } = await axios.get('/api/sum-transactions');
  //   setTransactionsAmount(data.totalAmount);
  // };

  const saveTransaction = useCallback(saveTransactionToDB, []);

  const checkAmount = useCallback((amount: number) => {
    console.log('amount', +amount);

    if (+amount <= 0) {
      console.log('amount', +amount);
      setErrorAmount('Mindestens 1€');
    } else {
      setErrorAmount('');
      return addDecimals(Number(amount));
    }

    return addDecimals(Number(amount));
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setToggleAmountField(true);
    const numWithEur = event.currentTarget.innerHTML;
    const numWithoutEur = numWithEur.slice(0, numWithEur.length - 1);

    setAmount(addDecimals(Number(numWithoutEur)));
  };
  const [{ isPending }] = usePayPalScriptReducer();

  const PayButtonWithPayPal = useCallback(() => {
    const paypalbuttonTransactionProps: PayPalButtonsComponentOptions = {
      createOrder(data, actions) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: addDecimals(+amount),
                currency_code: 'EUR',
              },
            },
          ],
        });
      },
      style: {
        layout: 'vertical',
        shape: 'rect',
        label: 'pay',
      },
      async onApprove(data, actions) {
        /**
         * data: {
         *   orderID: string;
         *   payerID: string;
         *   paymentID: string | null;
         *   billingToken: string | null;
         *   facilitatorAccesstoken: string;
         * }
         */
        //@ts-ignore
        const details = await actions.order.capture({});
        setIsPayed(true);
        if (details) {
          console.log('details', details);
          await saveTransaction('transactions', details, 'zelzal');
        }
        alert(
          'Thank you! ' + (details?.payer.name?.given_name ?? 'No details')
        );
        setTimeout(() => {
          setIsPayed(false);
        }, 3000);
      },
      onError: (err: any) => {
        alert('error occured ' + err);
        console.log(err);
      },
    };
    return (
      <>
        {isPending ? <h2>Load Smart Payment Button...</h2> : null}
        <PayPalButtons
          {...paypalbuttonTransactionProps}
          className="paypal"
          disabled={+amount <= 0}
        />
      </>
    );
  }, [amount, isPending, saveTransaction]);

  useEffect(() => {
    // const getTotalTranscationsFromDB = async () => {
    //   await getTotalTransactionsAmount();
    // };
    // getTotalTranscationsFromDB();
    checkAmount(+amount);
    PayButtonWithPayPal();
  }, [
    PayButtonWithPayPal,
    amount,
    toggleAmountField,
    saveTransaction,
    checkAmount,
  ]);

  return (
    <>
      <section>
        <header>
          <Row className="justify-content-center">
            <Image
              src="/assets/images/earthquake-syria-1.jpeg"
              className="header"
            />
          </Row>
        </header>
        <Container fluid>
          <div>
            <Link to="/">
              <Button className="nav-button button-color">
                <i className="fa-solid fa-arrow-left"></i>
              </Button>
            </Link>
          </div>
          <Row>
            <article>
              <Col>
                <p className="paragraph">{t('paragraph.bodylist1_text')}</p>
                <ul className="paragraph list li-checkout">
                  <li>{t('paragraph.bodylist1_element1')}</li>
                  <li>{t('paragraph.bodylist1_element2')}</li>
                </ul>
                <p className="paragraph">{t('paragraph.bodylist2_text')}</p>
                <ul className="paragraph list">
                  <li>{t('paragraph.bodylist2_element1')}</li>
                  <li>{t('paragraph.bodylist2_element2')}</li>
                </ul>
              </Col>
            </article>
          </Row>

          <section>
            <Row md="auto" className="justify-content-center text-center mb-3">
              <div className="ratio ratio-16x9">
                <video controls>
                  <title title="Takaful Austria - Earthquake urgent react" />
                  <source
                    src="/assets/videos/zelzal-new-place.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Row>
          </section>
          <div className="amount-boxes-wrapper">
            <div>
              <Button
                className="button-color"
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                10€
              </Button>
            </div>
            <div>
              <Button
                className="button-color"
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                25€
              </Button>
            </div>
            <div>
              <Button
                className="button-color"
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                35€
              </Button>
            </div>
            <div>
              <Button
                className="button-color"
                onClick={(event) => {
                  handleClick(event);
                  setAmount('');
                }}
              >
                Other
              </Button>
            </div>
          </div>
          <Row className="">
            <Col className="input-wrapper">
              {toggleAmountField && (
                <TextField
                  error={!!errorAmount}
                  variant="outlined"
                  className="mb-5 mt-5"
                  id="Amount"
                  label={t('amount.label')}
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setErrorAmount('');
                  }}
                  //@ts-ignore
                  placeholder={t('amount.placeholder')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}>
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '-30px',
                  color: 'red',
                }}
                hidden={!toggleAmountField}
              >
                {errorAmount}
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {isPayed === true ? (
              <p className="thanks" onClick={() => setIsPayed(false)}>
                {t('thanksmessage.text')}
              </p>
            ) : (
              <div className="paypal">
                <div>
                  <PayButtonWithPayPal />
                </div>
              </div>
            )}
          </Row>
          <Row className="justify-content-center">
            <span className="info">
              Wenn Sie ein Problem mit einer Zahlungsmethode haben, wählen Sie
              bitte eine andere
            </span>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ZelzalScreen;
