import { PayPalScriptOptions } from '@paypal/paypal-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import { CLIENT_ID } from './config/Config';
import Impressum from './components/Impressum';
import Contact from './components/Contact';
import About from './components/About';
import NotFound from './components/NotFound';
import NavBar from './components/NavBar';
import LandingPage from './Screens/LandingPage';
import ZelzalScreen from './Screens/ZelzalScreen';
import PumpScreen from './Screens/PumpScreen';
import ResidentialProjectScreen from './Screens/Cases/ResidentialProjectScreen';
import CancerScreen from './Screens/Cases/CancerScreen';
import EidScreen from './Screens/EidScreen';
import RamadanBasketScreen from './Screens/RamadanBasketScreen';
import RamadanFitraScreen from './Screens/RamadanFitraScreen';
import RamadanIftarScreen from './Screens/RamadanIftarScreen';
import RamadanScreen from './Screens/RamadanScreen';
import RamadanZakatScreen from './Screens/RamadanZakatScreen';

function App() {
  const payPalOptions: PayPalScriptOptions = {
    //@ts-ignore
    'client-id': CLIENT_ID,
    currency: 'EUR',
  };

  return (
    <PayPalScriptProvider options={payPalOptions}>
      <Router>
        <div className='wrapper'>
          <NavBar />
          <main className='main container'>
            <Routes>
              <Route path='*' element={<NotFound />} />
              <Route path='/' element={<LandingPage />} />
              <Route path='/zelzal' element={<ZelzalScreen />} />
              <Route path='/pump' element={<PumpScreen />} />
              <Route
                path='/residential-6'
                element={<ResidentialProjectScreen />}
              />
              <Route path='/cancer' element={<CancerScreen />} />

              {/* <Route path='/ramadan' element={<RamadanScreen />} /> */}
              <Route path='/eid' element={<EidScreen />} />

              {/* <Route path='/ramadan/basket' element={<RamadanBasketScreen />} />
              <Route path='/ramadan/iftar' element={<RamadanIftarScreen />} />
              <Route path='/ramadan/fitra' element={<RamadanFitraScreen />} />
              <Route path='/ramadan/zakat' element={<RamadanZakatScreen />} /> */}

              {/* <Route path="/gaza-nothilfe" element={<UrgentDonation />} /> */}

              <Route path='/impressum' element={<Impressum />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='/about' element={<About />} />
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </PayPalScriptProvider>
  );
}

export default App;
