import React, { useCallback, useEffect, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { saveTransactionToDB, addDecimals } from '../../lib/helper';
import { PayPalButtonsComponentOptions } from '@paypal/paypal-js';
import { Row, Container, Button, Image, Col } from 'react-bootstrap';
import { TextField } from '@mui/material';

const CancerScreen = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  const { t, i18n } = useTranslation('common');
  const isRtl = i18n.dir() === 'rtl';
  const [amount, setAmount] = useState('');
  const [isPayed, setIsPayed] = useState(false);
  const [toggleAmountField, setToggleAmountField] = useState(false);
  const [errorAmount, setErrorAmount] = useState('');

  const saveTransaction = useCallback(saveTransactionToDB, []);

  const checkAmount = useCallback((amount: number) => {
    console.log('amount', +amount);

    if (+amount <= 0) {
      console.log('amount', +amount);
      setErrorAmount('Mindestens 1€');
    } else {
      setErrorAmount('');
      return addDecimals(Number(amount));
    }

    return addDecimals(Number(amount));
  }, []);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setToggleAmountField(true);
    const numWithEur = event.currentTarget.innerHTML;
    const numWithoutEur = numWithEur.slice(0, numWithEur.length - 1);

    setAmount(addDecimals(Number(numWithoutEur)));
  };
  const [{ isPending }] = usePayPalScriptReducer();

  const PayButtonWithPayPal = useCallback(() => {
    const paypalbuttonTransactionProps: PayPalButtonsComponentOptions = {
      createOrder(data, actions) {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: addDecimals(+amount),
                currency_code: 'EUR',
              },
            },
          ],
        });
      },
      style: {
        layout: 'vertical',
        shape: 'rect',
        label: 'pay',
      },
      async onApprove(data, actions) {
        /**
         * data: {
         *   orderID: string;
         *   payerID: string;
         *   paymentID: string | null;
         *   billingToken: string | null;
         *   facilitatorAccesstoken: string;
         * }
         */
        //@ts-ignore
        const details = await actions.order.capture({});
        setIsPayed(true);
        if (details) {
          console.log('details', details);
          await saveTransaction('transactions', details, 'cancer');
        }
        alert(
          'Thank you! ' + (details?.payer.name?.given_name ?? 'No details')
        );
        setTimeout(() => {
          setIsPayed(false);
        }, 3000);
      },
      onError: (err: any) => {
        alert('error occured ' + err);
        console.log(err);
      },
    };
    return (
      <>
        {isPending ? <h2>Load Smart Payment Button...</h2> : null}
        <PayPalButtons
          {...paypalbuttonTransactionProps}
          className="paypal"
          disabled={+amount <= 0}
        />
      </>
    );
  }, [amount, isPending, saveTransaction]);

  useEffect(() => {
    // const getTotalTranscationsFromDB = async () => {
    //   await getTotalTransactionsAmount();
    // };
    // getTotalTranscationsFromDB();
    checkAmount(+amount);
    PayButtonWithPayPal();
  }, [
    PayButtonWithPayPal,
    amount,
    toggleAmountField,
    saveTransaction,
    checkAmount,
  ]);

  return (
    <div>
      <section>
        <header>
          <Row className="justify-content-center">
            <Image
              src="/assets/images/takaful-cancer-5.jpeg"
              className="header"
            />
          </Row>

          <Container fluid>
            <div>
              <Link to="/">
                <Button className="nav-button button-color">
                  <i className="fa-solid fa-arrow-left"></i>
                </Button>
              </Link>
            </div>
          </Container>

          <div className="mt-3">
            <h5 dir="rtl" lang="ar" className="text-center quran">
              بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ
            </h5>
            <h5 dir="rtl" lang="ar" className="text-center quran">
              &#1757; وَمَنْ أَحْيَاهَا فَكَأَنَّمَا أَحْيَا النَّاسَ جَمِيعًا
              &#1757;
            </h5>
            <h6 dir="rtl" lang="ar" className="text-center quran">
              المائدة [32]{' '}
            </h6>
          </div>
        </header>
      </section>

      <section>
        <article>
          <Row md="2" className="justify-content-center">
            <p className="paragraph">
              {t('Cancer.paragraph.residential-screen-body')}
            </p>
          </Row>
          <Row md="6" className="justify-content-center text-center">
            <p style={{ width: 'auto' }}>
              {t('Cancer.paragraph.residential-screen-list')}
            </p>
          </Row>
        </article>
        <Row md="auto" className="justify-content-center text-center mb-3">
          <div className="ratio ratio-16x9">
            <video controls>
              <title title="Takaful Austria - cancer project" />
              <source
                src="/assets/videos/takaful-cancer-3-1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Row>
      </section>

      <section>
        <div className="amount-boxes-wrapper">
          <div>
            <Button
              className="button-color"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              35€
            </Button>
          </div>
          <div>
            <Button
              className="button-color"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              50€
            </Button>
          </div>
          <div>
            <Button
              className="button-color"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              100€
            </Button>
          </div>

          <div>
            <Button
              className="button-color"
              onClick={(event) => {
                handleClick(event);
                setAmount('');
              }}
            >
              Other
            </Button>
          </div>
        </div>
        <Row className="">
          <Col className="input-wrapper">
            {toggleAmountField && (
              <TextField
                error={!!errorAmount}
                variant="outlined"
                className="mb-5 mt-5"
                id="Amount"
                label={t('amount.label')}
                type="number"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                  setErrorAmount('');
                }}
                //@ts-ignore
                placeholder={t('amount.placeholder')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: 'center' }}>
            <p
              style={{
                fontSize: '12px',
                marginTop: '-30px',
                color: 'red',
              }}
              hidden={!toggleAmountField}
            >
              {errorAmount}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {isPayed === true ? (
            <p className="thanks" onClick={() => setIsPayed(false)}>
              {t('thanksmessage.text')}
            </p>
          ) : (
            <div className="paypal">
              <div>
                <PayButtonWithPayPal />
              </div>
            </div>
          )}
        </Row>
        <Row className="justify-content-center">
          <span className="info">
            Wenn Sie ein Problem mit einer Zahlungsmethode haben, wählen Sie
            bitte eine andere
          </span>
        </Row>
      </section>
    </div>
  );
};

export default CancerScreen;
